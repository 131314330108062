import React from 'react';
import './happy-holidays.style.css';
interface IProps {
	[key: string]: any;
}

export const ChristmasDrawing = (props: IProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		id="animated-drawing"
		shapeRendering="geometricPrecision"
		textRendering="geometricPrecision"
		className="drawing"
		{...props}
	>
		<path
			className="stroke-1"
			fill="none"
			stroke="#ab031d"
			strokeDasharray={2263.96}
			strokeDashoffset={2263.96}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9}
			d="m5 756 47.513-96.656A5 5 0 0 1 57 656.55h26.843c.756 0 1.501.171 2.18.5l24.096 11.679c.345.167.709.294 1.083.378l43.74 9.841a5 5 0 0 0 5.96-3.71l32.208-134.117a4.995 4.995 0 0 1 2.188-3.057l19.98-12.643a5.002 5.002 0 0 1 2.674-.775h23.831a5.001 5.001 0 0 0 3.453-1.384l24.717-23.608a5.003 5.003 0 0 1 6.025-.673l25.952 15.558c1.2.72 2.647.903 3.989.506l37.031-10.955a5.001 5.001 0 0 1 4.987 1.291l39.016 39.743a5 5 0 0 0 7.191-.057l31.057-32.652a4.993 4.993 0 0 1 2.01-1.287l40.616-13.839a5.001 5.001 0 0 1 1.613-.267h120.861l42.86-5.881a4.996 4.996 0 0 1 4.183 1.386l34.578 33.972c2.295 2.254 6.014 1.758 7.692-.831.289-.445.563-.904.919-1.298l104.075-114.886a5.002 5.002 0 0 1 2.155-1.396L1113.5 301.5m-197-8.5c-20-3-33.5-21 0-72s62-56 49-9-69 311-65.5 331.5m182.5-390c-12 43.5-53 267-63 310"
		/>
		<path
			className="stroke-2"
			fill="none"
			stroke="#ab031d"
			strokeDasharray={995.95}
			strokeDashoffset={995.95}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9}
			d="M1153.5 334c-16-13.5-27-9-38 10.5s-16 62.5 5.5 35.5a950.42 950.42 0 0 0 9.93-12.668c3.27-4.239 14.96-1.245 15.89 4.024 1.79 10.161 4.35 19.757 7.68 22.644 7.5 6.5 15.5 14 32.5-8 10.92-14.138 19.79-42.732 25.12-65.078m0 0c2.97-12.425 4.84-22.919 5.38-27.922.33-3.09-1.82 8.264-5.38 27.922Zm0 0c-3.43 18.977-8.18 45.693-13.25 74.626m0 0c-13.5 76.886-29.37 169.422-29.37 173.952 0 5.797 20.98-105.61 38.64-178.169m-9.27 4.217a157.14 157.14 0 0 0 9.27-4.217m-9.27 4.217c-4.17 1.734-8.62 3.39-13.37 4.952m22.64-9.169c6.71-27.579 12.94-49.545 17.36-57.331 19-33.5 29-23.999 31.5-12 2.2 10.546-.63 45.423-48.86 69.331Z"
		/>
		<path
			className="stroke-3"
			fill="none"
			stroke="#ab031d"
			strokeDasharray={670.84}
			strokeDashoffset={670.84}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9}
			d="m1292.5 298-17.68 98.896m0 0-26.43 147.83m26.43-147.83c1.82-.873 3.68-1.818 5.56-2.83m-5.56 2.83c-9.11 4.378-17.11 6.947-21.82 7.104m-4.61 140.726-3.89 21.774c1.15-6.596 2.46-13.916 3.89-21.774Zm0 0c8.08-44.158 20.28-105.315 31.99-150.66m0 0c7.06-27.322 13.93-48.903 19.62-57.566 21-32 38.5-18 27.5 12-6.86 18.71-28.31 35.476-47.12 45.566Z"
		/>
		<path
			className="stroke-4"
			fill="none"
			stroke="#ab031d"
			strokeDasharray={705.17}
			strokeDashoffset={705.17}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9}
			d="M1363 329.5c-6.25 17.852-5.33 69.961 25.52 27.261 3.16-4.367 10.67-1.998 10.07 3.356-1.45 12.919-3.2 26.912-5.24 41.47m0 0c-13.3 95.228-38.82 214.632-74.35 215.913-43.47 1.566-2.59-133.667 74.35-215.913Zm0 0c21.3-22.762 45.35-41.465 71.15-52.087"
		/>
		<path
			className="stroke-5"
			fill="none"
			stroke="#ab031d"
			strokeDasharray={1129.86}
			strokeDashoffset={1129.86}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9}
			d="M1571.5 261.5c-27.5 5.5-29.5-59 15-74s-2.5 107-22 196.5c-15.6 71.6-23.5 135.833-25.5 159m182-386.5c-19.83 92.167-59.6 283.4-60 311M1445 413c-8-4-8.5-8.5 36.5-25s260.5-83.5 269.5-85"
		/>
		<path
			className="stroke-6"
			fill="none"
			stroke="#ab031d"
			strokeDasharray={755.24}
			strokeDashoffset={755.24}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9}
			d="M1755 348c-9.5 15-11 41 6 43 9.82 1.155 17.96-7.525 21.94-18.147m0 0c2.92-7.775 3.6-16.591 1.06-23.353-6-16-21-13-18.5 3.5 1.69 11.175 9.58 17.534 17.44 19.853Zm0 0c3.75 1.104 7.49 1.292 10.56.647 5.27-1.11 18.52-7.223 32.1-18.725m0 0c10.9-9.225 22.01-21.917 29.4-38.275 21-46.5 53.5-144.5 26-137-14.64 3.993-38.21 91.474-55.4 175.275Zm0 0c-15.08 73.59-25.24 144.341-20.1 153.225"
		/>
		<path
			className="stroke-7"
			fill="none"
			stroke="#ab031d"
			strokeDasharray={859.21}
			strokeDashoffset={859.21}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9}
			d="m1881 316.5-5 3m-3 25.5c-7.5 34.5-6.5 62.5 12.5 51.5s33.5-28.5 40.5-39c2.8-4.207 9.24-13.23 16.35-20.284m0 0c4.48-4.439 9.23-8.098 13.52-9.284m-13.52 9.284c4.72-5.433 9.41-8.448 13.52-9.284m-13.52 9.284c-6.79 7.833-13.64 20.689-18.85 39.284-13.78 49.165 22.53 13.033 52.03-29.987m-19.66-18.581c3.02-.837 5.81-.444 8.11 1.771m-8.11-1.771c3.12-.636 5.91-.011 8.11 1.771m0 0c2.19 2.109 3.94 5.871 5.02 11.797-.46-5.583-2.29-9.583-5.02-11.797Zm11.55 16.81c11.16-16.266 21.34-33.516 27.47-47.513 23-52.5 34-97.5 22.5-101-11.43-3.479-33.39 70.773-49.97 148.513Zm0 0c-13.88 65.06-23.99 132.562-21.03 158.987"
		/>
		<path
			className="stroke-8"
			fill="none"
			stroke="#ab031d"
			strokeDasharray={955.4}
			strokeDashoffset={955.4}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9}
			d="M2048.5 333.5c-12.5-9-24.5-12.5-35.5 8.5s-21 60.5 0 40c5.22-5.099 9.58-9.783 13.17-13.937 3.5-4.065 14.49-1.062 15.5 4.21 2.46 12.827 7.36 25.302 16.33 27.727 16.77 4.533 40.12-38.61 48.81-63.101m0 0c.89-2.521 1.63-4.845 2.19-6.899-.72 2.056-1.47 4.391-2.19 6.899Zm0 0c-4.78 16.609-8.36 40.864 5.19 42.601 5.67.727 11.42-3.016 16.75-8.853 4-4.385 15.94-.502 15.01 5.361a1947.108 1947.108 0 0 1-5.82 34.593m0 0c-16.63 93.776-43.59 204.751-72.44 206.899-35.49 2.643 2.87-123.317 72.44-206.899Zm0 0c22.56-27.096 48.4-49.738 76.06-62.101"
		/>
		<path
			className="stroke-9"
			fill="none"
			stroke="#ab031d"
			strokeDasharray={299.87}
			strokeDashoffset={299.87}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9}
			d="M2256.5 323c-32-8-60 21-30 64.5 10.18 14.762 17.48 28.199 21.02 40.586m0 0c6.9 24.114-.44 44.246-28.52 62.414-17.39 11.253.73-22.686 28.52-62.414Z"
		/>
		<path
			className="stroke-10"
			fill="none"
			stroke="#ab031d"
			strokeDasharray={1509.58}
			strokeDashoffset={1509.58}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9}
			d="m2247.52 428.086 144.24-174.379 51.84-1.796 35.421 9.589 89.429 12.591 53.24-136.378 39.34-12.932h45.01l48.46-27.005 50.2 17.791 69.26-11.466 74.43 42.115 56.5-33.954 74.486-14.486h183.441l86.423-5.88 69.21 36.727 58.92-100.963"
			transform="translate(-.28 5.032)"
		/>
	</svg>
)
